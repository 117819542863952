import "./App.css";
import React from "react";
import { Button, CssBaseline } from "@nextui-org/react";

function App() {
  return (
    <div className="w-screen	h-screen" style={{ backgroundColor: "#192a56" }}>
      <div className="contentBlock text-center text-white">
        <h1 className="text-6xl">DD9</h1>
        <h3 className="text-4xl py-4">Coming soon...</h3>
        <CssBaseline>
          <a href="mailto:contact@dd9.dev">
            <Button
              auto
              flat
              color="primary"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Get in touch
            </Button>
          </a>
        </CssBaseline>
      </div>
    </div>
  );
}

export default App;
